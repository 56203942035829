
import {computed, defineComponent, ref, watch} from 'vue'
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import Froala from "@/components/base/editor/Froala.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import DropZoneUpload from "@/components/base/upload/DropZoneUpload.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import DateTimePicker from "@/components/base/datetime/DateTimePicker.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import {Field} from "vee-validate";
import {CALL_CATEGORY} from "@/core/config/Constant";
import DocumentService from "@/core/services/DocumentService";
import {Client} from "@stomp/stompjs";
import store from "@/store";
import ActivityService from "@/core/services/ActivityService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "PhoneCallNewForm",
  components: {
    EntityLoading,
    Field,
    UserListSelect,
    DateTimePicker,
    BaseForm,
    DropZoneUpload,
    BaseRadio,
    Froala,
    FormErrorMessage,
  },
  props: {
    activityId: {},
    activity: {},
  },
  emits: ['onSavedActivity'],
  setup(props) {
    const document = ref({});
    const client = computed<Client>(() => store.state.WebSocketModule.client);
    const file = ref('');
    const extracting = ref(false);
    const model = ref<any>(props.activity);
    const uploaded = ref(false);
    watch(() => props.activity, cb => {
      model.value = cb;
    })

    const submitting = ref(false);
    return {
      submitting,
      CALL_CATEGORY,
      extracting,
      model,
      uploaded,
      file,
      document,
      client
    }
  },
  mounted() {
    this.onSub();
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      ActivityService.create(this.model).then(res => {
        this.model.notifies = []
        this.$emit('onSavedActivity', res)
      }).finally(() => {
        this.submitting = false
      })
    },
    onFileSelected(file) {
      this.file = file;
    },
    onUpload() {
      this.extracting = true;
      if (!this.file) return
      const data = {file: this.file}
      this.extracting = true;
      DocumentService.uploadPhoneCall(data).then(res => {
        this.model.documentIdReq = res.id
      }).finally(() => {
      })
    },
    onSub() {
      if (this.client && this.client.connected) {
        this.client.subscribe('/topic/public/phone_call', (data) => {
          const analyzeData = JSON.parse(data.body)
          const callData = JSON.parse(analyzeData.json);
          this.model.title = callData.call_title
          this.model.note = callData.call_summary
          this.model.category = callData.category
          this.model.contactName = callData.contact
          this.extracting = false;
          this.uploaded = true;
        })
      } else {
        setTimeout(this.onSub, 2000)
      }
    }
  }
})
